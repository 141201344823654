






import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ProjectsTableComponent from '../components/ProjectsTableComponent.vue';
import * as _ from 'lodash';
import { GeneralSearchCriteria } from '@/store/models';
import { EmptyGeneralSearchCriteria } from '@/store/models-empty';

@Component({
  components: {
    ProjectsTableComponent,
  },
})
export default class ProjectsVue extends BaseComponent {
  protected baseRoute: string = '/projects';
  private updateProjectKey: number = 0;
  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);

  protected changeCurrentOrg() {
    this.updateProjectKey++;
  }
}
